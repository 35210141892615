(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@draftkings/simplebet-match-tracker", [], factory);
	else if(typeof exports === 'object')
		exports["@draftkings/simplebet-match-tracker"] = factory();
	else
		root["@draftkings/simplebet-match-tracker"] = factory();
})(self, function() {
return 